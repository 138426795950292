import React, { useMemo, useEffect } from 'react'
import { styled } from '../stitches/stitches.config'
import * as Sentry from '@sentry/gatsby'

import PanicDaangni from '../assets/reactComponents/PanicDaangni'
import { errorMessage } from '../utils/errorMessageGlobal'
import useSetSeedDesign from '../hooks/useSetSeedDesign'
import WebScreenHelmet from '../components/WebScreenHelmet/WebScreenHelmet'
import replaceLocation from '../utils/replaceLocation'

const NotFoundPage: React.FC = () => {
  const country = process.env.REACT_APP_COUNTRY_CODE as CountryCode
  const errorMessageByCountry = useMemo(() => errorMessage(country), [country])
  const handleClickCloseButton = () => {
    replaceLocation(`https://www.daangn.com`)
  }

  useEffect(() => {
    if (typeof window === 'undefined') return
    Sentry.captureMessage(`Server Error Occured - ${window.location.pathname}`)
  }, [])
  useSetSeedDesign()

  return (
    <>
      <WebScreenHelmet />
      <NotFoundContainer>
        {country === 'kr' && (
          <ImageContainer>
            <PanicDaangni />
          </ImageContainer>
        )}
        <Title>{errorMessageByCountry.title}</Title>
        <Description>{errorMessageByCountry.subTitle}</Description>
        <CloseText onClick={handleClickCloseButton}>
          {errorMessageByCountry.close}
        </CloseText>
      </NotFoundContainer>
    </>
  )
}

const NotFoundContainer = styled('div', {
  width: '100%',
  height: '90%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
})

const ImageContainer = styled('div', {
  paddingBottom: 20,
  maxWidth: 300,
  maxHeight: 300,
})

const Title = styled('h2', {
  width: ' 80%',
  fontWeight: 'bold',
  margin: 0,
  wordBreak: 'break-word',
  textAlign: 'center',
})

const Description = styled('p', {
  width: '80%',
  fontSize: '14px',
  margin: 0,
  marginTop: 10,
  wordBreak: 'break-word',
  textAlign: 'center',
})

const CloseText = styled('p', {
  width: '60%',
  fontSize: 18,
  margin: 0,
  marginTop: 10,
  wordBreak: 'break-word',
  textAlign: 'center',
  color: '#ff8a3d',
  cursor: 'pointer',
  '&:hover': {
    fontWeight: 'bold',
  },
})

export default NotFoundPage
